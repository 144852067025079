.brand-logo {
    width: 122px;
    height: 129px;
    margin-top: 40px;
    z-index: 3;

    > a {
        display: block;
    }

    .brand-logo-grey-path {
        transition: fill .5s ease;
    }
}

header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1040px;
    margin: 0 auto 27px auto;
    padding: 0 20px;
	position: relative;
}

.main-nav-toggle {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: 67px;
    right:0;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
}

.main-nav-container {
    position: relative;
    z-index: 2;

    > span {
		position: absolute;
		right:0;
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        background: $light-grey;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

        &:first-of-type {
			top: 67px;
            transform-origin: 0% 0%;
        }

		&:nth-of-type(2) {
			top:77px;
		}

        &:nth-last-child(2) {
			top:87px;
            transform-origin: 0% 100%;
        }
    }

    .main-nav-toggle:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: white;
    }

    .main-nav-toggle:checked ~ span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }

    .main-nav-toggle:checked ~ span:nth-last-child(2) {
        transform: rotate(-45deg) translate(0, -1px);
    }

    .main-nav {
        position: absolute;
        width: 100vw;
		height:100vh;
		background-color: $emal-grey;
		font-size: 1.625em;
		line-height: 1.625em;
		margin:0;
		padding:160px 20px 20px 90px;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        /* to stop flickering of text in safari */
		opacity: 0;
		transform-origin: top;
		transform:translateX(calc(-100vw + 20px));
		transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
		text-align: right;

		> li {
			position: relative;
            border-bottom: 1px solid white;

			a {
				display: inline-block;
				text-decoration: none;
				margin: 3px 0;
				color:white;
	
				&:hover,
				&:active,
				&.active,
				&.sub-active {
					color: $emal-red;
                    font-weight: 500;
				}
			}

			&.main-nav-logo {
				position: absolute;
				left:20px;
				top:40px;
                border:none;
			}
		}

		@include mappy-bp(medium) {
			position: relative;
			display: flex;
			flex-flow: row wrap;
			/* This aligns items to the end line on main-axis */
			justify-content: flex-end;
			padding: 0;
			margin: 0;
			margin-top: 96px;
			background:none;
			width: initial;
			font-size: 1.375em;
			line-height: 1.375em;
			transform: none;
			width: initial;
			height: initial;
			text-align: initial;
			opacity: 1;

			> li {
				position: initial;
				padding-left: 30px;
                border-bottom: none;
		
				&:first-child {
					padding-left: 0;
				}

				a {
					color: $emal-grey;
				}

                a.active + .main-nav-sub {
                    opacity:  1;
                    pointer-events: initial;
                }

				&.main-nav-logo {
					display: none;
				}
			}
		}
    }

	.main-nav-sub {
		list-style-type: none;
        -webkit-font-smoothing: antialiased;
		overflow: hidden;

		> li {
            border-bottom: 1px dotted white;

            &:first-child {
                border-top: 1px dotted white;
            }

            &:last-child {
                border-bottom: none;
            }

			a {
				display: block;
				font-size: 1.25rem;
				line-height: 1.8;
			}
		}

		@include mappy-bp(medium) {
			max-height: initial;
			display: flex;
			justify-content: flex-end;
			position: absolute;
			right: 0;
			padding: 0;
			margin: 0;
            opacity: 0;
            pointer-events: none;

			> li {
				padding-left: 28px;
                border-bottom:none;

                &:first-child {
                    border-top: none;
                }

				a {
					font-size: 1.125rem;
				}
			}
		}
	}

	.main-nav-toggle:checked ~ .main-nav{
        opacity: 1;
        pointer-events: initial;
    }

	.main-nav-toggle:checked ~ .main-nav-logo{
        display: block;
    }

	@include mappy-bp(medium) {
		.main-nav-toggle,
		> span {
			display: none;
		}
	}
}

.main-nav-open {
    // logo grey colors turn white on nav open
    .brand-logo-grey-path {
        fill: #ffffff;
    }
}

.box-nav {
    list-style:none;
    padding:0; 
    margin:0;
    margin-top:20px;

    li {
        display: block;

        &.box-nav-active a {
            color:$emal-red;
        }
    }

    .box-nav-active ~ li {
        a {
            color:white;
            font-weight:400;
        }
    }

    a {
        font-size: 1.875em;
        line-height: 1.2;
        font-weight:500;
        color: $emal-red;
        text-decoration: none;
        text-transform: uppercase;

    }
}

.main-banner {
    color: white;
    overflow: hidden;
    margin-bottom:20px;
    position: relative;
    background-color:#e2e2e2;

    @include mappy-bp(medium) {
        margin-bottom: initial;
	}

    .bg {
        display: block;
        content: '';
        padding-top: 59.34%;
        position: relative;
    }

    .bg img {
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
        position: absolute;
        opacity:0;
		transform: scale(1.2);
        will-change: transform, opacity;
        animation: zoomOutBg 3s ease-in-out forwards, fadeIn .7s ease forwards;
    }
}

.main-banner-svg {
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    z-index: 1;

    .text-g-one,
    .text-g-two,
    .text-g-three,
    .text-g-four,
    .banner-red-dot {
        opacity: 0;
        -webkit-font-smoothing: antialiased;
        animation: fadeIn .7s ease forwards;
    }

    .banner-red-dot {
        animation-delay: 3s;
    }

    .text-g-one {
        animation-delay: 4s;
    }

    .text-g-two {
        animation-delay: 5s;
    }

    .text-g-three {
        animation-delay: 6s;
    }

    .text-g-four {
        animation-delay: 7s;
    }
}

.grey-box,
.light-grey-box,
.white-box {
    height: 100%;
    width:100%;
    color: white;
    padding: 30px 30px 40px 30px;
    margin-bottom: 20px;
}

.grey-box {
    background-color: $emal-grey;
    padding-bottom: 30px;

    > h3 {
        font-size: 1.875em;
        margin: 0 0 18px 0;
        font-weight: 300;
    }

    > p {
        margin: 0;
        font-size: 1.875em;
        line-height: 1.2;
    }
}

.light-grey-box {
    background-color: $light-grey;

    @include mappy-bp(medium) {
        &.add-minheight {
            min-height: 369px;
        }
	}

    > h3 {
        font-size: 1.875em;
        margin: 0 0 18px 0;
        font-weight: 300;
    }

    > p,
    ul {
        margin: 0;
        font-size: 1.625em;
        line-height: 1.2;
        font-weight: 400;
    }

    > ul {
        list-style: disc;
        padding:0;
        padding-left: 20px;

        li::marker {
            font-size:1rem;
        }
    }
}

.white-box {
    color: $emal-grey;
    border: 1px solid $emal-grey;
    box-shadow: 1px 2px 4px 2px rgb(0 0 0 / 20%);

    > p {
        font-size: 1.625em;
        margin-bottom:0;
    }
}

.op-year-count {
    color: $emal-red;
    font-size: 1.625em;
    text-transform: uppercase;
    margin: 0 0 20px 0;

    > span {
        display: block;
        font-size: 3em;
        line-height: 0.72;
        font-weight: 600;
    }
}

.home-references-wrapper {
    width: 100%;

    .reference-item:first-child {
        padding-top: 0;
        border-top: none;
    }
}

.references-wrapper,
.team-wrapper,
.management-wrapper {
    width:100%;
}

.team-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .team-item {
        margin-right: 25px;

        @include mappy-bp(medium) {
            width: 100%;
            max-width: 430px;
        }
    }
}

.reference-item {
    display: flex;
    flex-flow: column;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px dotted $emal-grey;

    @include mappy-bp(medium) {
        flex-flow: row;
    }

    .reference-item-image {
        width: 100%;

        > img {
            width: 100%;
            height: auto;
        }

        @include mappy-bp(medium) {
            width: initial;
            max-width: 230px;
            min-width: 230px;
            margin-right: 20px;
        }
    }

    .reference-item-content {
        h3 {
            font-size: 1.875em;
            margin-top: 15px;
            margin-bottom: 0;
        }

        p {
            font-size: 1em;
            margin: 0;
        }

        .ref-item-title {
            margin-bottom:3px;
        }

        .ref-item-year {
            line-height: 1;
        }

        .ref-item-work {
            margin-bottom: 4px;
        }

        .ref-item-address,
        .ref-item-meta {
            color: black;
            margin-bottom: 20px;
            line-height: 1.4;
        }
    }
}

.team-item {
    display: flex;
    flex-flow: column;
    padding-bottom: 25px;

    @include mappy-bp(medium) {
        flex-flow: row;
    }

    h3 {
        font-size: 1.875em;
        font-weight: 500;
        margin:0;

        @include mappy-bp(medium) {
            font-size:1.1875em;
        }
    }

    p {
        margin: 0;
        line-height: 1.4;
    }

    .team-content-meta {
        font-size:1.2em;
        line-height: 1.3;
        margin-top:10px;

        a {
            font-size:1.3rem;
            padding:4px 0;
            line-height: 1;
            display: block;
            color:$emal-grey;
            text-decoration: none;

        }

        @include mappy-bp(medium) {
            margin-top:30px;
            font-size:1em;

            a {
                padding: 2px 0;
                font-size:1rem;
            }
        }
    }

    .team-item-image {
        width: 100%;

        > img {
            width: 100%;
            height: auto;
        }

        @include mappy-bp(medium) {
            width: initial;
            max-width: 233px;
            margin-right: 20px;
        }
    }

    .team-item-content {
        display: flex;
        flex-flow: column;
        flex-grow: 1;
        margin-top:10px;

        @include mappy-bp(medium) {
            align-self: flex-end;
            margin-top:initial;
        }
    }
}

.page-content {
    display: flex;
    flex-flow: column;

    @include mappy-bp(medium) {
        max-width: 615px;
        padding-left:20px;
    }

    h3, h4 {
        font-size:1.875em;
    }

    h4 {
        margin-bottom: 20px;
    }

    p {
        font-size: 1.375em;
        line-height: 1.3;
        margin-top: 0;
    }
}

.product-partners,
.service-section {
    border-top:1px dotted $light-grey;
    padding-top:20px;

    h4 {
        font-size: 1.125rem;
        margin-bottom: 0;
    }

    h3 {
        font-size: 1.875em;
    }

    p {
        font-size: 1.375em;
        line-height: 1.3;
        margin-top: 0;
    }
}

.product-banner-image,
.service-image {

    > img {
        width:100%;
        height:auto;
    }
    
    @include mappy-bp(medium) {
        width: initial;
        max-width: 615px;
        margin-top:20px;

        > img {
            max-width: 615px;
        }
    }
}

.product-partner-list {
    list-style: none;
    margin:0;
    padding:0;
    display: flex;
    flex-wrap: wrap;

    @include mappy-bp(medium) {
        max-width: 615px;
        padding-left: 20px;
        margin-top:35px;
    }

    li {
        flex: 1 0 21%;

        > svg {
            width:160px;
        }

        @include mappy-bp(medium) {
            > svg {
                width: initial;
            }
        }
    }
}

.service-section {

    @include mappy-bp(medium) {
        h4 {
            margin-top:0;
        }
    }

    .service-text {
        @include mappy-bp(medium) {
            padding-left: 20px;
        }
    }
}

.product-partners h4 {
    margin-top:0;
}

.quote-item {
    padding:0;
    margin:0;
    margin-bottom:30px;

    blockquote {
        margin-left:0;
        margin-right:0;
    }

    cite {
        font-style: normal;
    }

    @include mappy-bp(medium) {
        blockquote {
            margin-right: initial;
        }
        
        figcaption {
            text-align: right;
        }
    }
}

.team-contact-box {
    h3 {
        text-transform: uppercase;
    }

    @include mappy-bp(medium) {
       min-height: 223px;
	}
}

.main-banner-team {
    margin-bottom:0;
    margin-top: 20px;

    @include mappy-bp(medium) {
        margin-top: initial;
     }
}

@keyframes zoomOutBg {
    100% {
        transform: scale(1);
        -webkit-backface-visibility: hidden;
    }
}

@keyframes fadeIn {
    100% {
        opacity: 1;
    }
}