.text-red {
    color:$emal-red;
}

.text-grey {
    color:$emal-grey;
}

.link-grey {
    color:$emal-grey;
}

.flow-col-reverse-small {
    flex-flow: column-reverse;

    @include mappy-bp(medium) {
        flex-flow: column;
    }
}

.h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

.flex-one {
    flex:1;
}

.no-padding-left {
    padding-left: 0;
}

.r-pb {
    padding-bottom:0;
}

.mt-20 {
    margin-top:20px;
}

.r-mb {
    margin-bottom:0;
}

.r-mt {
    margin-top:0 !important;
}

.text-bold {
    font-weight: 600;
}

.text-semi-bold {
    font-weight: 500;
}


.r-m {
    margin:0;
}

.r-p {
    padding:0;
}

.r-mb-desktop {
    @include mappy-bp(medium) {
        margin-bottom:0;
    }
}

.cf:after {
    display: block;
    clear: both;
    content: "";
}

#cookies-eu-banner {
    background: rgba(0,0,0,.7);
    color: #fff;
    padding: 6px;
    font-size: 13px;
    text-align: center;
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    left: 0;
    z-index: 70;

    a {
        color:white;
    }

    button {
        border:none;
        margin:0 6px;
        height:28px;
        padding:4px 8px;
    }
}

.footer-spacer {
    color: #bdbdbd;
}