// Saira
@font-face {
	font-family: 'saira-semicondensed';
	font-style: normal;
	font-weight: 400;
	src:
		local("Saira-Semicondensed"),
		local("Saira-Semicondensed"),
		url("../fonts/saira-semicondensed/woff2/SairaSemiCondensed-Regular.woff2") format("woff2"),
		url("../fonts/saira-semicondensed/woff/SairaSemiCondensed-Regular.woff") format("woff");
    font-display: swap;
}

@font-face {
	font-family: 'saira-semicondensed';
	font-style: normal;
	font-weight: 500;
	src:
		local("Saira-Semicondensed"),
		local("Saira-Semicondensed"),
		url("../fonts/saira-semicondensed/woff2/SairaSemiCondensed-Medium.woff2") format("woff2"),
		url("../fonts/saira-semicondensed/woff/SairaSemiCondensed-Medium.woff") format("woff");
    font-display: swap;
}

@font-face {
	font-family: 'saira-semicondensed';
	font-style: normal;
	font-weight: 600;
	src:
		local("Saira-Semicondensed"),
		local("Saira-Semicondensed"),
		url("../fonts/saira-semicondensed/woff2/SairaSemiCondensed-Bold.woff2") format("woff2"),
		url("../fonts/saira-semicondensed/woff/SairaSemiCondensed-Bold.woff") format("woff");
    font-display: swap;
}

