html {
    color: $emal-grey;
    font-family: 'saira-semicondensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	font-size: 14px;
	box-sizing: border-box;

    // Typography
	-ms-overflow-style: scrollbar;
	-ms-text-size-adjust: 100%; /* stylelint-disable-line property-no-vendor-prefix */
	-webkit-text-size-adjust: 100%; /* stylelint-disable-line property-no-vendor-prefix */

	font-kerning: normal;
	-moz-font-feature-settings: "kern"; /* stylelint-disable-line property-no-vendor-prefix */
	-ms-font-feature-settings: "kern"; /* stylelint-disable-line property-no-vendor-prefix */
	-webkit-font-feature-settings: "kern"; /* stylelint-disable-line property-no-vendor-prefix */
	font-feature-settings: "kern";

    // Media Queries
	@media screen and (-webkit-min-device-pixel-ratio: 2),
	screen and (min-resolution: 2dppx) {
		/* stylelint-disable-next-line property-no-unknown */
		font-smoothing: subpixel-antialiased; // Only apply to devices that support font smoothing: https://www.zachleat.com/web/font-smooth/
	}
}

@media screen and (min-width: 320px) {
	html {
	  font-size: calc(14px + 2 * ((100vw - 320px) / 680));
	}
}
@media screen and (min-width: 1000px) {
	html {
		font-size: 16px;
	}
}

*, *:before, *:after {
	box-sizing: inherit;
}

html,
body {
	height:100%;
	margin:0;
}

h1, h2, h3, h4, h5 {
	font-weight: 300;
}

.content-wrapper {
    width:100%;
    max-width: 1040px;
    margin: 0 auto;
	padding:0 20px;

	> .row {
		display:flex;
		flex-flow: wrap;

		.row-item {
			width: 100%;
			&.narrow {
				display: flex;
			}

			&:not(.narrow) {
				flex-grow: 1;
			}
		}

		// Breakpoints
		@include mappy-bp(medium) {
			flex-flow: row;

			.row-item {
				width: initial;
			}

			.row-item.narrow {
				width:36.5%;
			}

			.row-item + .row-item {
				margin-left:2%;
			}
		}

		& + .row {
			margin-top:20px;
		}

		&.row-items-start {
			align-items: flex-start;
		}
	}
}

.footer {
	color:white;
	background-color: $emal-grey;
	margin-top:110px;

	.footer-logo {
		margin-bottom: 10px;
		margin-right: 10px;
	}

	.footer-content {
		width:100%;
		max-width: 1040px;
		min-height: 100px;
		margin: 0 auto;
		padding:30px 20px 30px 20px;
	}

	.footer-text,
	.footer-logo,
	.footer-claim{
		display: inline-block;
	}

	.footer-meta-links {
		list-style: none;
		margin:16px 40px 12px 0;
		padding:0;

		li {
			display: inline-block;
			margin-right:5px;
		}

		a {
			display: block;
			line-height: 1.4;
			color:#bdbdbd;
			padding:4px 0;
			font-size:1.3rem;
			line-height: 1.4;
			text-decoration: none;
		}
	}

	.footer-content-wide {
		float:left;
	}

	.footer-claim {
		float:right
	}

	.footer-text {
		margin-bottom: 20px;
		font-size:1.3rem;
		line-height: 1.4;
        margin:4px 0;

		address a, span {
			display: inline-block;
			margin-right:40px;
			font-style: normal;
		}

		a {
			color:white;
			text-decoration: none;
			height:50px;
			line-height: 50px;
		}
	}

	@include mappy-bp(medium) {
		.footer-logo {
			margin-bottom: 0;
		}

		.footer-text {
			font-size: 1rem;
			margin-left:80px;
			margin-bottom: 0;

			a {
				margin: 0;
				height: initial;
				line-height: initial;
			}
		}

		.footer-claim {
			margin-top:24px;
		}

		.footer-meta-links a {
			font-size: 1rem;
			line-height: normal;
		}
	}
}
